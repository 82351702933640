import React from "react";
import CustomSidebar from "./CustomSidebar";
import Navbar from "./Navbar";
import { Outlet, useLocation } from "react-router-dom";

const Layout = () => {
  const location = useLocation();

  // Define routes where the sidebar and navbar should not be displayed
  const hiddenRoutes = ["/AddInfo"];

  // Check if the current route matches any hidden route
  const isHidden = hiddenRoutes.includes(location.pathname);

  return (
    <div className="flex h-screen">
      {!isHidden && (
        <div
          className="fixed top-0 left-0 h-full w-64"
          style={{
            background:
              "linear-gradient(241deg, rgba(0,133,255,1) 0%, rgba(13,10,150,1) 35%)",
          }}
        >
          <CustomSidebar />
        </div>
      )}
      <div className={`flex flex-col flex-1 ${!isHidden ? "ml-64" : ""}`}>
        {!isHidden && (
          <div
            style={{
              background:
                "linear-gradient(241deg, rgba(0,133,255,1) 0%, rgba(13,10,150,1) 35%)",
            }}
          >
            <Navbar />
          </div>
        )}
        <div className="flex flex-col flex-1 p-4 bg-gray-100 overflow-auto">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Layout;
