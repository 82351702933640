import React from "react";
import RentOverviewTable from "./components/RentOverViewTable";

function Reports({ mobileNumber }) {
  return (
    // <Layout>
      <div
        className=""
      
      >
        <div className="flex justify-between gap-10 p-4 ">
          {/* <div>
            <p>Overview</p>
            <RentIncome />
          </div> */}
        </div>
        <div>
          <p>Recent Rents</p>
          <RentOverviewTable />
        </div>
      </div>
    // </Layout>
  );
}

export default Reports;
