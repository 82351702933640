import React, { useState } from "react";
import Avatar from "@mui/material/Avatar";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../Utility/AuthProvider";
import ProfileModal from "./Modals/ProfileModal";
import APICall from "../Utility/APICall";
import { paths } from "../Utility/Constants";

function Navbar() {
  const { deleteJwt, user, jwt } = useAuth();
  const navigate = useNavigate();
  const [profileModalOpen, setProfileModalOpen] = useState(false);

  const handleLogout = async () => {
    try {
      // Make the logout API call
      const response = await APICall(
        "PUT",
        paths.user.logout,
        user.entity_id,
        undefined,
        jwt
      );

      console.log("Response from logout call:", response);

      // Check if the response is successful
      if (response?.status === "success") {
        // Delete JWT, clear local storage, and navigate
        deleteJwt();
        localStorage.clear();
        navigate("/");
      } else {
        console.error(
          "Logout API call failed:",
          response?.message || "Unknown error"
        );
        // Optionally show an error message to the user
      }
    } catch (error) {
      console.error("Error during logout API call:", error);
      // Optionally show an error message to the user
    }
  };

  // const handleLogout = () => {
  //   deleteJwt();
  //   localStorage.clear();
  //   navigate("/");
  // };

  const handleAvatarClick = () => {
    setProfileModalOpen(true);
  };

  const handleCloseProfileModal = () => {
    setProfileModalOpen(false);
  };

  return (
    <div className="mt-2 pb-2">
      <div className="flex items-center justify-end gap-4">
        <input
          type="text"
          className="border border-gray-300 rounded-md p-2"
          placeholder="Search.."
        />
        <NotificationsActiveIcon className="ml-4 mr-2" />
        <Avatar
          alt="User Avatar"
          src={user?.media?.profile_picture?.url}
          onClick={handleAvatarClick}
        />
        <button
          onClick={handleLogout}
          className="p-2 w-40 bg-blue-500 rounded-lg"
        >
          LogOut
        </button>
      </div>
      <ProfileModal
        open={profileModalOpen}
        onClose={handleCloseProfileModal}
        user={user}
      />
    </div>
  );
}

export default Navbar;
