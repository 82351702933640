import React, { useState, useEffect } from "react";
import APICall from "../../Utility/APICall";
import { paths } from "../../Utility/Constants";
import { useAuth } from "../../Utility/AuthProvider";
import Lottie from "lottie-react";
import loaderAnimation from "../../assets/animations/loader.json";

function Agreements() {
  const { jwt } = useAuth();
  const [allAgreements, setAllAgreements] = useState(null);
  const [errorAgreements, setErrorAgreements] = useState("");
  const [loadingAgreements, setLoadingAgreements] = useState(true);

  // Calculate tenure in months and years
  const calculateTenure = (createdDate, vacatingDate) => {
    const created = new Date(createdDate);
    const vacating = new Date(vacatingDate);

    const years = vacating.getFullYear() - created.getFullYear();
    const months = vacating.getMonth() - created.getMonth() + years * 12;

    const displayYears = Math.floor(months / 12);
    const displayMonths = months % 12;

    return `${displayYears > 0 ? `${displayYears} year ` : ""}${displayMonths} months`;
  };

  // Fetch agreements
  useEffect(() => {
    const fetchAgreements = async () => {
      setLoadingAgreements(true); // Start loading
      try {
        const response = await APICall(
          "GET",
          paths.agreement.fetch,
          undefined,
          undefined,
          jwt
        );

        // Sort the agreements by `vacating_on` date (nearest date first)
        const sortedAgreements = response.response.sort((a, b) => {
          const vacatingA = new Date(a.vacating_on);
          const vacatingB = new Date(b.vacating_on);
          return vacatingA - vacatingB; // Ascending order
        });

        // Add tenure for each agreement
        sortedAgreements.forEach((agreement) => {
          agreement.tenure = calculateTenure(
            agreement.created,
            agreement.vacating_on
          );
        });

        setAllAgreements(sortedAgreements);
      } catch (error) {
        setErrorAgreements(error);
        console.error("Error fetching agreements:", error);
      } finally {
        setLoadingAgreements(false);
      }
    };

    fetchAgreements();
  }, [jwt]); // Dependency array ensures this runs when `jwt` changes

  return (
    <div>
      <p className="text-xl font-bold mb-4">Agreements</p>
      {loadingAgreements && (
        <div className="flex justify-center">
          <Lottie animationData={loaderAnimation} loop={true} />
        </div>
      )}
      {errorAgreements && (
        <p className="text-red-500">Error: {errorAgreements.message}</p>
      )}
      {allAgreements && (
        <div className="overflow-x-auto p-10">
          <table className="min-w-full bg-white border border-gray-200">
            <thead className="text-sm bg-gray-100">
              <tr className="text-left">
                <th className="py-2 px-4 border-b">Sr.No.</th>
                <th className="py-2 px-4 border-b">Building Name</th>
                <th className="py-2 px-4 border-b">Unit Name</th>
                <th className="py-2 px-4 border-b">Tenure</th>
                <th className="py-2 px-4 border-b">Created</th>
                <th className="py-2 px-4 border-b">Vacating On</th>
                <th className="py-2 px-4 border-b">Updated On</th>
              </tr>
            </thead>
            <tbody className="text-xs">
              {allAgreements.map((agreement, index) => (
                <tr key={index} className="hover:bg-gray-50">
                  <td className="py-2 px-4 border-b">{index + 1}</td>
                  <td className="py-2 px-4 border-b">
                    {agreement.building_name}
                  </td>
                  <td className="py-2 px-4 border-b">{agreement.unit_name}</td>
                  <td className="py-2 px-4 border-b">{agreement.tenure}</td>
                  <td className="py-2 px-4 border-b">
                    {new Date(agreement.created).toLocaleDateString()}
                  </td>
                  <td className="py-2 px-4 border-b">
                    {new Date(agreement.vacating_on).toLocaleDateString()}
                  </td>
                  <td className="py-2 px-4 border-b">
                    {agreement.updated
                      ? new Date(agreement.updated).toLocaleDateString()
                      : new Date(agreement.created).toLocaleDateString()}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default Agreements;
