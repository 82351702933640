import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CustomImage from "../../Utility/CustomImage";
import { useAuth } from "../../Utility/AuthProvider";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function PaymentModal({ isVisible, onClose, paymentDetails }) {
  const { jwt } = useAuth();
  if (!isVisible) return null;
  const evidenceImage = paymentDetails?.media?.[0]?.location;
  // const base_url = cloudStorageBucket.base;
  const base_url = "https://renown-backend-assignment.el.r.appspot.com";

  return (
    <Modal
      open={isVisible}
      onClose={onClose}
      aria-labelledby="payment-modal-title"
      aria-describedby="payment-modal-description"
    >
      <Box sx={style}>
        <Typography id="payment-modal-title" variant="h6" component="h2">
          Payment Evidences
        </Typography>
        <Typography id="payment-modal-description" sx={{ mt: 2 }}>
          <p>
            <strong>Building:</strong> {paymentDetails.building_name}
          </p>
          <p>
            <strong>Flat:</strong> {paymentDetails.unit_name}
          </p>
          <p>
            <strong>Tenant:</strong> {paymentDetails.tenant_name}
          </p>
          <p>
            <strong>Amount:</strong> {paymentDetails.amount}
          </p>
          <p>
            <strong>Status:</strong>{" "}
            {paymentDetails.payment_status === "success" ? "Paid" : "Not Paid"}
          </p>
        </Typography>
        <div className="mt-4">
          <h3 className="font-bold mb-2">Uploaded Evidences:</h3>
          {paymentDetails.media && paymentDetails.media.length > 0 ? (
            <ul>
              {paymentDetails.media.map((mediaItem, index) => (
                <li key={index} className="mt-2">
                  <CustomImage
                    location={mediaItem.location}
                    jwt={jwt}
                    className="object-cover"
                    params={{ quality: "high" }}
                    onError={() =>
                      console.log(`Error loading image for media ${index}`)
                    }
                  />
                </li>
              ))}
            </ul>
          ) : (
            <p>No evidences uploaded.</p>
          )}
        </div>
      </Box>
    </Modal>
  );
}
