import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import CustomImage from "../../Utility/CustomImage";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const ProfileModal = ({ open, onClose, user, jwt }) => {
  const [profilePictureLocation, setProfilePictureLocation] = useState('');

  useEffect(() => {
    const storedUserData = JSON.parse(localStorage.getItem('userData'));
    if (storedUserData && storedUserData.media && storedUserData.media.profile_picture) {
      setProfilePictureLocation(storedUserData.media.profile_picture.location);
    }
  }, []);

  if (!user) {
    return null; // Or some loading indicator
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="profile-modal-title"
      aria-describedby="profile-modal-description"
    >
      <Box sx={style}>
        <h2 id="profile-modal-title">Profile</h2>
        <div className="flex items-center justify-center mb-4">
          <CustomImage
            location={profilePictureLocation}
            jwt={jwt}
            className="w-20 h-20 rounded-full"
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Phone Number
          </label>
          <div className="flex items-center">
            <span>{user.mobile}</span>
            <button className="ml-2 text-blue-500">Edit</button>
          </div>
        </div>
        <div className="mb-4">
          <button className="w-full p-2 text-left">Account</button>
        </div>
        <div className="mb-4">
          <button className="w-full p-2 text-left">Notifications</button>
        </div>
        <div className="mb-4">
          <button className="w-full p-2 text-left">Payments</button>
        </div>
        <div className="mb-4">
          <button className="w-full p-2 text-left">Settings</button>
        </div>
        <div className="mb-4">
          <button className="w-full p-2 text-left">Help & Support</button>
        </div>
      </Box>
    </Modal>
  );
};

export default ProfileModal;
