import React, { createContext, useContext, useEffect, useState } from "react";
import { jwtDecode } from "jwt-decode";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [jwt, setJwt] = useState("");
  const [user, setUser] = useState(null);

  useEffect(() => {
    const storedToken = localStorage.getItem("jwtToken");
    const storedUser = localStorage.getItem("userData");

    if (storedToken && storedUser) {
      setJwt(storedToken);
      setUser(JSON.parse(storedUser));
    }
  }, []);

  // const storeJwt = (jwt, userData) => {
  //   setJwt(jwt);
  //   setUser(userData);
  //   console.log("User Data saved in Context", userData);
  //   localStorage.setItem("jwtToken", jwt);
  //   localStorage.setItem("userData", JSON.stringify(userData));
  // };
  const storeJwt = (jwt, userData) => {
    setJwt(jwt);
    setUser(userData);
    console.log("JWT Token saved in Context:", jwt);
    console.log("User Data saved in Context:", userData);
    localStorage.setItem("jwtToken", jwt);
    localStorage.setItem("userData", JSON.stringify(userData));
  };

  const deleteJwt = () => {
    setJwt("");
    setUser(null);
    localStorage.removeItem("jwtToken");
    localStorage.removeItem("userData");
  };

  return (
    <AuthContext.Provider value={{ jwt, user, storeJwt, deleteJwt }}>
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => {
  return useContext(AuthContext);
};

export { AuthProvider, useAuth };
