import React, { useState } from "react";
import { FaCamera, FaTimes } from "react-icons/fa";
import CustomButton from "../Buttons/CustomButton";
import CustomImage from "../../Utility/CustomImage";
import ImageModal from "./ImageModal";

const AddTenantModal = ({ isOpen, onClose, onAddTenant, jwt }) => {
  const [tenantName, setTenantName] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [profilePicture, setProfilePicture] = useState(null);
  const [profilePicturePreview, setProfilePicturePreview] = useState(null);
  const [aadharFront, setAadharFront] = useState(null);
  const [aadharBack, setAadharBack] = useState(null);
  const [panCard, setPanCard] = useState(null);
  const [rentAgreement, setRentAgreement] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isDocumentModalOpen, setDocumentModalOpen] = useState(false);
  const [currentDocumentType, setCurrentDocumentType] = useState("");
  const [currentDocumentSide, setCurrentDocumentSide] = useState("");

  const handleFileChange = (e, setFile, setPreview = null) => {
    const file = e.target.files[0];
    setFile(file);
    if (file && setPreview) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleFormSubmit = () => {
    if (tenantName.length < 3 || !mobileNumber) {
      alert("Please fill all the mandatory fields.");
      return;
    }

    const tenantDetails = {
      tenantName,
      mobileNumber,
      profilePicture,
      aadharFront,
      aadharBack,
      panCard,
      rentAgreement,
    };
    onAddTenant(tenantDetails);
  };

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  const handleCloseModal = () => {
    setSelectedImage(null);
  };

  const openDocumentModal = (documentType, documentSide = null) => {
    setCurrentDocumentType(documentType);
    setCurrentDocumentSide(documentSide);
    setDocumentModalOpen(true);
  };

  const handleDocumentUpload = (e) => {
    const file = e.target.files[0];
    if (currentDocumentType === "aadhar" && currentDocumentSide === "front") {
      setAadharFront(file);
    } else if (currentDocumentType === "aadhar" && currentDocumentSide === "back") {
      setAadharBack(file);
    } else if (currentDocumentType === "pan") {
      setPanCard(file);
    } else if (currentDocumentType === "rent") {
      setRentAgreement(file);
    }
    setDocumentModalOpen(false);
  };

  const removeImage = (type) => {
    if (type === "profile") {
      setProfilePicture(null);
      setProfilePicturePreview(null);
    } else if (type === "aadharFront") {
      setAadharFront(null);
    } else if (type === "aadharBack") {
      setAadharBack(null);
    } else if (type === "pan") {
      setPanCard(null);
    } else if (type === "rent") {
      setRentAgreement(null);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-50 z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg w-1/2 max-h-[80vh] overflow-y-auto">
        <h2 className="text-xl font-semibold mb-4">Add Tenant</h2>

        {/* Profile Picture */}
        <div className="mt-4 relative">
          <label className="block text-sm font-medium text-gray-700">
            Profile Picture <span className="text-red-500">*</span>
          </label>
          <div className="flex items-center justify-center mt-2">
            <div className="relative w-40 h-40 rounded-full border border-gray-300 flex items-center justify-center overflow-hidden">
              {profilePicturePreview ? (
                <>
                  <img
                    src={profilePicturePreview}
                    alt="Profile"
                    className="w-full h-full object-cover"
                  />
                  {/* <FaTimes
                    className="absolute top-1 right-1 text-red-500 cursor-pointer"
                    onClick={() => removeImage("profile")}
                  /> */}
                </>
              ) : (
                <FaCamera className="text-gray-400 text-2xl" />
              )}
              <input
                type="file"
                accept="image/*"
                onChange={(e) => handleFileChange(e, setProfilePicture, setProfilePicturePreview)}
                className="absolute inset-0 opacity-0 cursor-pointer"
              />
            </div>
          </div>
        </div>

        {/* Tenant Name */}
        <div className="mt-4 relative">
          <label
            htmlFor="tenantName"
            className="block text-sm font-medium text-gray-700"
          >
            Tenant Name <span className="text-red-500">*</span>
          </label>
          <input
            id="tenantName"
            type="text"
            value={tenantName}
            onChange={(e) => setTenantName(e.target.value)}
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
            minLength="3"
            required
          />
        </div>

        {/* Mobile Number */}
        <div className="mt-4 relative">
          <label
            htmlFor="mobileNumber"
            className="block text-sm font-medium text-gray-700"
          >
            Mobile Number <span className="text-red-500">*</span>
          </label>
          <input
            id="mobileNumber"
            type="tel"
            value={mobileNumber}
            onChange={(e) => setMobileNumber(e.target.value)}
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
            required
          />
        </div>

        {/* Documents Section */}
        <div className="mt-4 relative">
          <h3 className="text-md font-medium text-gray-700 mb-2">Add Documents</h3>

          {/* Upload Aadhar Card */}
          <div className="mt-2 relative">
            <label className="block text-sm font-medium text-gray-700">
              Upload Aadhar Card
            </label>
            <div className="flex gap-4 items-center">
              <button
                className="bg-gray-200 p-2 rounded-md"
                onClick={() => openDocumentModal("aadhar", "front")}
              >
                {aadharFront ? "Re-upload Aadhar Front" : "Upload Aadhar Front"}
              </button>
              {aadharFront && (
                <FaTimes
                  className="text-red-500 cursor-pointer"
                  onClick={() => removeImage("aadharFront")}
                />
              )}
              <button
                className="bg-gray-200 p-2 rounded-md"
                onClick={() => openDocumentModal("aadhar", "back")}
              >
                {aadharBack ? "Re-upload Aadhar Back" : "Upload Aadhar Back"}
              </button>
              {aadharBack && (
                <FaTimes
                  className="text-red-500 cursor-pointer"
                  onClick={() => removeImage("aadharBack")}
                />
              )}
            </div>
          </div>

          {/* Upload PAN Card */}
          <div className="mt-2 relative">
            <label className="block text-sm font-medium text-gray-700">
              Upload PAN Card
            </label>
            <div className="flex items-center">
              <button
                className="bg-gray-200 p-2 rounded-md"
                onClick={() => openDocumentModal("pan")}
              >
                {panCard ? "Re-upload PAN Card" : "Upload PAN Card"}
              </button>
              {panCard && (
                <FaTimes
                  className="text-red-500 cursor-pointer"
                  onClick={() => removeImage("pan")}
                />
              )}
            </div>
          </div>

          {/* Upload Rent Agreement */}
          <div className="mt-2 relative">
            <label className="block text-sm font-medium text-gray-700">
              Upload Rent Agreement
            </label>
            <div className="flex items-center">
              <button
                className="bg-gray-200 p-2 rounded-md"
                onClick={() => openDocumentModal("rent")}
              >
                {rentAgreement ? "Re-upload Rent Agreement" : "Upload Rent Agreement"}
              </button>
              {rentAgreement && (
                <FaTimes
                  className="text-red-500 cursor-pointer"
                  onClick={() => removeImage("rent")}
                />
              )}
            </div>
          </div>
        </div>

        <div className="flex justify-between items-center mt-4">
          <CustomButton
            tailwindClasses={`bg-blue-500 text-white ${tenantName.length < 3 || !mobileNumber ? 'opacity-50 cursor-not-allowed' : ''}`}
            label="Add Tenant"
            onClick={handleFormSubmit}
            disabled={tenantName.length < 3 || !mobileNumber}
          />
          <button
            className=" bg-gray-500 text-white py-2 px-4 rounded"
            onClick={onClose}
          >
            Close
          </button>
        </div>

        {selectedImage && (
          <ImageModal isOpen={!!selectedImage} onClose={handleCloseModal}>
            <img src={URL.createObjectURL(selectedImage)} alt="Selected Document" className="w-60 h-60" />
          </ImageModal>
        )}

        {isDocumentModalOpen && (
          <div className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-50 z-50">
            <div className="bg-white p-6 rounded-lg shadow-lg w-1/3">
              <h2 className="text-xl font-semibold mb-4">Upload {currentDocumentType} {currentDocumentSide ? `(${currentDocumentSide})` : ""}</h2>
              <input
                type="file"
                accept="image/*"
                onChange={handleDocumentUpload}
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
              />
              <button
                className="mt-4 bg-gray-500 text-white py-2 px-4 rounded"
                onClick={() => setDocumentModalOpen(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AddTenantModal;
